import React from "react";
import './home.css';
import {Footer, Header} from "../../widgets/Header";
import VideoBanner from "../video/VideoBanner";
import 'bootstrap/dist/css/bootstrap.min.css';

function Home() {
    return (
        <div className="hfeed site" id="page">
            <Header />
            <VideoBanner />
            {/* Banner con video de fondo */}
            <div className="home-banner" style={{fontFamily:'Montserrat,sans-serif'}}>
                <div className="home-banner-overlay"></div>
                <div className="home-banner-content">
                    <h1>¿Quienes Somos?</h1>
                    <p>
                        <strong>ASGL & ASOCIADOS</strong> es un conjunto de empresas mexicanas vanguardistas, dinámicas y en constante crecimiento. Poseemos un alto valor comercial a nivel nacional e internacional.
                        <br /><br />
                        Los principales motores que han llevado a cada una de nuestras filiales a ser reconocidas en cada uno de los sectores especializados son la <strong>excelencia, el compromiso y los valores</strong>.
                        <br /><br />
                        En <strong>ASGL & ASOCIADOS</strong> nos enfocamos en el bienestar de nuestras comunidades y del país, es por ello que estamos conformados por cinco giros que se han definido como estratégicos: comercial con valor ambiental, construcción, telecomunicaciones, servicios y turismo. De esta manera contribuimos a la responsabilidad social, así como al desarrollo de las entidades donde estamos presentes.
                    </p>

                     {/* Sección de Misión y Visión */}
                    <div className="mission-vision container-fluid bg-black text-white py-5">
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <h2 className="mission-title">MISIÓN</h2>
                                <p>Contribuir al desarrollo empresarial de México para ofrecer soluciones especializadas y de alta calidad.</p>
                            </div>
                            <div className="col-md-6 text-center">
                                <h2 className="vision-title">VISIÓN</h2>
                                <p>Ser un grupo impulsor a nivel internacional coadyuvando en el desarrollo integral de nuestras filiales.</p>
                            </div>
                        </div>
                    </div>

                    <div className="mission-vision container-fluid bg-black text-white py-5">
                        <div className="row">
                            <div className="col-md-6 text-center">
                                <a href="/about">
                                    <i class="ri-door-open-fill icon-home"></i>
                                    <h2 className="text-white" style={{fontWeight:'700'}}>NUESTROS SERVICIOS</h2>
                                </a>
                            </div>
                            <div className="col-md-6 text-center">
                                <a href="/contact">
                                    <i class="ri-shake-hands-fill icon-home"></i>
                                    <h2 className="text-white" style={{fontWeight:'700'}}>CONTACTANOS</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;