import React, { useEffect, useState } from 'react';
import { Footer, Header } from "../../widgets/Header";
import './about.css';

function About() {
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        const handleScroll = () => {
            const scrollY = window.scrollY || window.pageYOffset;
            const newOpacity = Math.min(scrollY / 500, 1); // Ajusta 200 para controlar la velocidad del efecto
            setOpacity(newOpacity);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="about-section">
                <div className="background-container">
                    <img src="/assets/img/photo-1493794179168-82ca7cb00437.jpg" alt="Background" className="background-image" />
                    <div className="overlay" style={{ opacity }}></div>
                    <h2>NUESTROS SERVICIOS</h2>
                    <div className="container services-container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="service-item">
                                    <a href='/libera-beneficios'>
                                    <img src="/assets/img/libera.jpg" alt="Servicio 1" className="service-image" />
                                    </a>
                                    <h3>COBRANZA</h3>
                                    <p>Ayudamos a las personas ofreciendo una solución financiera, así como a las empresas al cobro de su cartera vencida a través de personal especializado. </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-item">
                                <a href='/libera'>
                                    <img src="/assets/img/libera-beneficios.png" alt="Servicio 2" className="service-image" />
                                    </a>
                                    <h3>CONTACT CENTER</h3>
                                    <p>Creamos campañas de atención a clientes, ventas, post-venta, gestión de incidencias y reclamaciones, programas de lealtad, retención de clientes, estudios de satisfacción a clientes y estudios de mercado, utilizando distintos canales de comunicación.</p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-item">
                                <a href='/dmc-anfitryon'>
                                    <img src="/assets/img/anfitryon.jpg" alt="Servicio 3" className="service-image" />
                                    </a>
                                    <h3>DMC</h3>
                                    <p>Generamos experiencias únicas e inigualables en tus viajes.</p>
                                </div>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-4">
                                <div className="service-item">
                                <a href='/alearth'>
                                    <img src="/assets/img/alearth.png" alt="Servicio 1" className="service-image" />
                                    </a>
                                    <h3>productos ecológicos</h3>
                                    <p>
                                    Contribuimos al cuidado del medio ambiente y promovemos una cultura de consciencia ambiental a través de nuestros productos.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-item">
                                <a href='/shintegration'>
                                    <img src="/assets/img/logomario-150x150.png" alt="Servicio 2" className="service-image" />
                                    </a>
                                    <h3>TECNOLOGIA</h3>
                                    <p>
                                    Desarrollamos software e integramos todo tipo de hardware, así como asesoría y soporte que requieras para cumplir tus metas.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-4">
                                <div className="service-item">
                                <a href='/ivma-constructora'>
                                    <img src="/assets/img/ivma.png" alt="Servicio 3" className="service-image" />
                                    </a>
                                    <h3>CONSTRUCCIÓN</h3>
                                    <p>
                                    Desarrollamos proyectos modernos, únicos y de alta plusvalía en diferentes zonas del país con alta calidad en materiales, en conjunto con inversionistas.
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <a href="/contact">
                                    <i class="ri-shake-hands-fill icon-about"></i>
                                    <h3 className="text-white" style={{fontWeight:'700'}}>CONTACTANOS</h3>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

export default About;