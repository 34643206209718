import React, { useEffect, useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import ReCAPTCHA from 'react-google-recaptcha';
import Swal from 'sweetalert2';
import { Footer, Header } from "../../widgets/Header";
import './contact.css';

function Contact() {

    const form = useRef();
    const [isSending, setIsSending] = useState(false);
    const [captchaValue, setCaptchaValue] = useState(null);

    // ⚙️ Manejar envío de formulario
    const sendEmail = (e) => {
        e.preventDefault();

        // Verificar si el captcha está validado
        if (!captchaValue) {
            Swal.fire({
                icon: 'warning',
                title: 'Verificación requerida',
                text: 'Por favor, verifica que no eres un robot',
            });
            return;
        }

        setIsSending(true);

        emailjs
            .sendForm('service_d3fvcib', 'template_z1o0h7m', form.current, {
                publicKey: 'WnmEDDA35L-jEAeox',
            })
            .then(
                () => {
                    setIsSending(false);
                    Swal.fire({
                        icon: 'success',
                        title: '¡Mensaje enviado!',
                        text: 'Tu mensaje ha sido enviado correctamente.',
                        timer: 3000,
                    });
                    form.current.reset(); // Limpiar formulario
                    setCaptchaValue(null); // Resetear captcha
                },
                (error) => {
                    setIsSending(false);
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: `Hubo un problema al enviar el mensaje: ${error.text}`,
                        timer: 3000,
                    });
                }
            );
    };

    // ⚙️ Manejar cambio en el captcha
    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="contact-container">
            <div className="contact-left">
                <img 
                    src="/assets/img/contact-background.jpg" // Ruta de la imagen
                    alt="Contact Background" 
                    className="contact-image"
                />
                <div className="contact-overlay">
                    <h2>Contacto</h2>
                    <p><strong>Correo:</strong></p>
                    <a href='mailto:contacto@asglasociados.com.mx'><p>contacto@asglasociados.com.mx</p></a>
                    <p><strong>Teléfono:</strong></p>
                    <p>(55)-51-61-15-68</p>
                    <p><strong>Dirección:</strong></p>
                    <p>Avenida añil, Eje 3 Sur 571, colonia Granjas México, 6 piso, despacho 603-BIS, delegación Iztacalco, Ciudad de México, CDMX</p>
                </div>
            </div>
            <div className="contact-right">
                <h2>Contáctanos</h2>
                <p>¡Contáctenos y un asesor especializado te dará a conocer a detalle todo nuestros servicios!</p>
                <form className="contact-form" id='form' ref={form} onSubmit={sendEmail}>
                    <div className="form-group">
                        <span>Nombre *</span>
                        <input type="text" name="name" id="name" required />
                    </div>
                    <div className="form-row">
                        <div className="form-group">
                            <span>Correo *</span>
                            <input type="email" name="email" id="email" required />
                        </div>
                        <div className="form-group">
                            <span>Teléfono *</span>
                            <input type="tel" maxLength="10" name="phone" id="phone" required />
                        </div>
                    </div>
                    <div className="form-group">
                        <span>Asunto</span>
                        <input type="text" name="subject" id="subject" />
                    </div>
                    <div className="form-group">
                        <span>Mensaje *</span>
                        <textarea required name="message" id="message"></textarea>
                    </div>
                    {/* reCAPTCHA v2 */}
                    <ReCAPTCHA
                        sitekey="6Leo6vIqAAAAAC0pZmBI_W5gfgjn86NY95rIe_L8"
                        onChange={handleCaptchaChange}
                    />
                    <button type="submit" id="button" disabled={isSending}>
                        {isSending ? 'Enviando...' : 'Enviar'}
                    </button>
                </form>
            </div>
        </div>
            <Footer />
        </div>
    )
}

export default Contact