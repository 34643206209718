import React from 'react'
import "remixicon/fonts/remixicon.css";
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './components/inicio/Home';
import About from './components/about/About';
import {Company, DMC, IVMA, LiberaBeneficios, Libera, Shintegrations} from './components/company/Company';
import Contact from './components/contact/Contact';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Home />}></Route>
        <Route path='/about' element={<About />}></Route>

        <Route path='/alearth' element={<Company />}></Route>
        <Route path='/dmc-anfitryon' element={<DMC />}></Route>
        <Route path='/ivma-constructora' element={<IVMA />}></Route>
        <Route path='/libera-beneficios' element={<LiberaBeneficios />}></Route>
        <Route path='/libera' element={<Libera />}></Route>
        <Route path='/shintegration' element={<Shintegrations />}></Route>

        <Route path='/contact' element={<Contact />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
