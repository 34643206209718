import React, { useEffect, useState } from 'react';
import { Footer, Header } from "../../widgets/Header";
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './company.css';

export function Company() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/alearth/slide1.jpg',
        '/assets/img/slider/alearth/slide2.png',
        '/assets/img/slider/alearth/slide3.jpg',
        '/assets/img/slider/alearth/slide4.jpg',
        '/assets/img/slider/alearth/slide5.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "ALEARTH",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos Alearth!</b></p>
                    <p style="margin-bottom: 1.6em;">Aquí podrás encontrar productos ecológicos de uso diario, doméstico y personal.</p>
                    <p style="margin-bottom: 1.6em;">Cuando hablamos de un producto ecológico nos referimos a lo obtenido de una forma natural, evitando el uso de productos químicos durante la extracción de la materia prima o durante la fabricación del producto final.</p>
                    <p style="margin-bottom: 1.6em;">Al usarlos evitamos daños en el ecosistema y reducimos las toxinas en el ambiente, además cuidamos y consentimos nuestro cuerpo con productos libres de químicos obteniendo múltiples beneficios como mejorar y proteger la piel, además de evitar irritaciones entre otros.</p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b> Somos una empresa mexicana que ofrece productos ecológicos a la sociedad con la finalidad de impulsar una cultura de consciencia ambiental contribuyendo a la mejora del medio ambiente.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b> En el Corto Plazo convertirnos en una pieza angular a favor de la conservación del medio ambiente que cambie positivamente en el estilo de vida y comportamiento de la sociedad.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestro Propósito! </b> Crear una comunidad consciente y comprometida con nuestro planeta difundiendo información veraz sobre las alternativas de consumo responsable mediante nuestras redes sociales, campañas sociales entre otras, con la intención de evitar residuos innecesarios y llevar un nuevo estilo de vida, apoyando al mismo tiempo a productores y artesanos mexicanos.</p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/alearth.png" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export function DMC() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/dmc/slide1.jpg',
        '/assets/img/slider/dmc/slide2.jpg',
        '/assets/img/slider/dmc/slide3.jpg',
        '/assets/img/slider/dmc/slide4.jpeg',
        '/assets/img/slider/dmc/slide5.jpeg',
        '/assets/img/slider/dmc/slide6.jpeg',
        '/assets/img/slider/dmc/slide7.jpeg',
        '/assets/img/slider/dmc/slide8.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "ANFITRYON DMC",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos Anfitryon DMC!</b></p>
                    <p style="margin-bottom: 1.6em;">Somos el enlace entre tú y la mejor experiencia turística a nivel nacional e internacional.</p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b> Somos una agencia de viajes mexicana que ofrece experiencias con el objetivo que tu viaje sea inolvidable y placentero desde la planeación hasta su término.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b> Ser una empresa competitiva y alentadora a viajar con alta calidad y originalidad en las experiencias a precios justos.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em;">Ofrecemos solucione para todos, desde cualquier tipo de grupo, ya sean corporativos y/o familiares</p>
                    <p style="margin-bottom: 1.6em;">Personalizamos los viajes adaptándonos a las necesidades de nuestros clientes, garantizando comodidad y diversión.</p>
                    <p style="margin-bottom: 1.6em;">Nuestro servicio integral incluye: las mejores opciones de servicios y productos en el destino, crear programas únicos y negociar con los proveedores locales para obtener las mejores condiciones desde el alojamiento, excursiones, traslados, hasta todo lo necesario para su evento.</p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/anfitryon.jpg" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export function IVMA() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/ivma/slide1.jpg',
        '/assets/img/slider/ivma/slide2.jpg',
        '/assets/img/slider/ivma/slide3.jpg',
        '/assets/img/slider/ivma/slide4.jpg',
        '/assets/img/slider/ivma/slide5.jpg',
        '/assets/img/slider/ivma/slide6.jpg',
        '/assets/img/slider/ivma/slide7.jpg',
        '/assets/img/slider/ivma/slide8.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "IVMA CONSTRUCTORA",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos IVMA CONSTRUCTORA!</b></p>
                    <p style="margin-bottom: 1.6em;">Trabajamos de la mano con inversionistas para el desarrollo de nuevos proyectos, implementando lo mejor en Ingeniería para que tus proyectos tengan gran valor y plusvalía en el mercado, utilizando diseños modernos, vanguardistas y haciendo de una construcción arquitectónica una obra de arte.   </p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b> satisfacer las necesidades de nuestros clientes antes, durante y después de un proyecto, dando cumplimiento a los estándares de calidad y a los plazos acordados por éste, gracias a la exigencia en el control de calidad de nuestros productos terminados.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b> Ser la empresa constructora y de remodelaciones de referencia a nivel nacional, liderando el mercado por medio de la responsabilidad, y eficiencia, cumpliendo a tiempo con todos y cada uno de los trabajos encomendados, fomentando el control y la calidad en el servicio.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em;">Ofrecemos una amplia gama de soluciones innovadoras a nivel habitacional, deportivo, oficinista, turístico y recreativo en remodelaciones, ampliaciones, decoración, arquitectura y diseño de interiores; optimizando los espacios en acogedores y funcionales, creando un ambiente perfecto para la colaboración productiva y/o de descanso.</p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/ivma.png" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export function LiberaBeneficios() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/libera-beneficios/slide1.jpg',
        '/assets/img/slider/libera-beneficios/slide2.png',
        '/assets/img/slider/libera-beneficios/slide3.jpg',
        '/assets/img/slider/libera-beneficios/slide4.jpg',
        '/assets/img/slider/libera-beneficios/slide5.png',
        '/assets/img/slider/libera-beneficios/slide6.jpg',
        '/assets/img/slider/libera-beneficios/slide7.jpg',
        '/assets/img/slider/libera-beneficios/slide8.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "Libera beneficios",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos Libera beneficios!</b></p>
                    <p style="margin-bottom: 1.6em;"> ¡Libera te ayuda a recuperar tu dinero y tu libertad financiera!</p>
                    <p style="margin-bottom: 1.6em;">Conoce nuestros servicios y descubre que solucionar tus problemas financieros es más fácil de lo que imaginabas.</p>
                    <p style="margin-bottom: 1.6em;">Estudiamos a fondo tu cartera de clientes para conseguir resultados óptimos, después del estudio de la base de datos te entregamos una proyección del éxito en el cobro de tu cartera vencida, tu satisfacción es nuestra prioridad y que recuperes tu dinero es nuestra especialidad.</p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b> Contamos con personal altamente especializado en servicios de cobranza; brindando soluciones y calidad en los procesos, apegados al marco legal, obteniendo con esto, el cumplimiento de resultados óptimos de nuestros clientes.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b> Ser una empresa líder en consultoría, asesoría y servicios de cobranza cumpliendo con los requerimientos y satisfacción de nuestros clientes; diversificando nuestros servicios para lograr rentabilidad de negocio.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em; font-weight: bold; font-style: italic;">Servicios de Contact Center</p>
                    <p style="margin-bottom: 1.6em;">
                        <ul style="margin-bottom: 1.6em;">
                            <li>Atención al cliente</li>
                            <li>Venta y Postventa</li>
                            <li>Gestión de incidencias y reclamaciones.</li>
                            <li>Programas de Lealtad</li>
                            <li>Retención de clientes</li>
                            <li>Estudios de satisfacción de clientes</li>
                            <li>Estudios de mercado</li>
                        </ul>
                    </p>

                    <p style="margin-bottom: 1.6em; font-weight: bold; font-style: italic;">Servicios de gestión de cobranza</p>
                    <p style="margin-bottom: 1.6em;">
                        <ul>
                            <li>Ética de trato con el cliente.</li>
                            <li>Confidencialidad</li>
                            <li>Calidad en el servicio.</li>
                        </ul>
                    </p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/libera.jpg" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export function Libera() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/libera/slide1.jpg',
        '/assets/img/slider/libera/slide2.jpg',
        '/assets/img/slider/libera/slide3.jpg',
        '/assets/img/slider/libera/slide4.jpg',
        '/assets/img/slider/libera/slide5.jpg',
        '/assets/img/slider/libera/slide6.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "Libera",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos Libera!</b></p>
                    <p style="margin-bottom: 1.6em;"> Te ayudamos a ampliar la comunicación con tu cliente mediante los distintos canales, además de poder saber su opinión con respecto a tu producto o servicio, orientarlo y solucionar sus dudas, afiliar clientes a tu marca, así mismo saber su experiencia al adquirir un producto o servicio.</p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b>  Brindar soluciones tecnológicas de alta calidad para lograr puentes de comunicación entre la empresa y el cliente con la finalidad de buscar la satisfacción del cliente en su máxima expresión.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b>  Ser un contact center que ofrece alta tecnología y la diversidad de canales, así como al personal con la mayor calidez humana, donde la comunicación se vea reflejada en relaciones interpersonales y no automatizada.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em; font-weight: bold; font-style: italic;">Servicios de Contact Center</p>
                    <p style="margin-bottom: 1.6em;">
                        <ul style="margin-bottom: 1.6em;">
                            <li>Inbounde</li>
                            <li>Outbound</li>
                            <li>Mailing</li>
                            <li>Chat (robot, whatsapp,)</li>
                            <li>Redes sociales</li>
                        </ul>
                    </p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/libera-beneficios.png" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export function Shintegrations() {

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const images = [
        '/assets/img/slider/shintegration/slide1.jpg',
        '/assets/img/slider/shintegration/slide2.jpg',
        '/assets/img/slider/shintegration/slide3.jpg',
        '/assets/img/slider/shintegration/slide4.jpg',
        '/assets/img/slider/shintegration/slide5.jpg',
        '/assets/img/slider/shintegration/slide6.jpg',
        '/assets/img/slider/shintegration/slide7.jpg',
    ];

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4, // Muestra 4 imágenes a la vez
        slidesToScroll: 1, // Avanza de 1 en 1
        autoplay: true, // Habilita el autoplay
        autoplaySpeed: 2000, // Cambia de slide cada 2 segundos (2000 ms)
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1, // Avanza de 1 en 1
                }
            }
        ]
    };

    const company = {
        title: "SHINTEGRATION",
        options: [
            {
                title: "NOSOTROS",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Hola Somos Shintegration!</b></p>
                    <p style="margin-bottom: 1.6em;"> Empresa 100% mexicana dedicada al desarrollo de soluciones tecnológicas empresariales e integrales para apoyar en la mejora continua de los procesos, así como en facilitar cada uno de sus servicios profesionales, te proveemos del mejor servicio para el desarrollo de software, la integración de todo tipo de hardware, así como la asesoría y soporte que requieras para cumplir tus metas!</p>
                `,
            },
            {
                title: "MISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Misión! </b>  Establecer las herramientas tanto tecnológicas como de operación para la mejora continua de los procesos de nuestros clientes particulares y/o de gobierno, así como proporcionar el soporte y asesoría en TI.</p>
                `,
            },
            {
                title: "VISIÓN",
                content: `
                    <p style="margin-bottom: 1.6em;"><b>¡Nuestra Visión! </b>  Consolidarnos en el mercado como la mejor empresa de desarrollo, generando procesos, métricas e integrando las tecnologías de la información a las soluciones personalizadas de nuestros clientes.</p>
                `,
            },
            {
                title: "SERVICIOS",
                content: `
                    <p style="margin-bottom: 1.6em;">Cada desarrollo requiere del recurso humano con la experiencia necesaria para brindar la calidad esperada por el cliente, así como la garantía del servicio y la atención personalizada que estamos convencidos de ofrecer a nuestros clientes.</p>
                    <p style="margin-bottom: 1.6em;">
                        <ul>
                            <li>Seguridad pública</li>
                            <li>Geolocalización y monitoreo de unidades</li>
                            <li>Vídeo vigilancia y CCTV</li>
                            <li>Hardware</li>
                            <li>Soporte técnico</li>
                            <li>Puntos de venta</li>
                            <li>Administración y configuración de soluciones en la nube</li>
                            <li>Apps móviles</li>
                            <li>Inteligencia artificial-ambientes cognitivos</li>
                            <li>Facturación electrónica</li>
                            <li>Capacitación</li>
                            <li>Reportaría</li>
                            <li>Redes y telefonía IP</li>
                        </ul>
                    </p>
                `,
            },
        ],
    };

    return (
        <div className="hfeed site" id="page">
            <Header />
            <div className="alearth-container">
                <div className="text-center">
                    <img src="/assets/img/logomario-150x150.png" alt="Alearth" className="center-image" />
                </div>
                <div className="slider-wrapper">
                    <Slider {...settings}>
                        {images.map((img, index) => (
                            <div key={index}>
                                <img src={img} alt={`Slide ${index + 1}`} className="slider-image" />
                            </div>
                        ))}
                    </Slider>
                </div>
                {/* Acordeón */}
                <div className="accordion-container">
                    <h2>{company.title}</h2> {/* Título de la compañía */}
                    <div className="accordion">
                        {company.options.map((option, index) => (
                            <div
                                key={index}
                                className={`accordion-item ${activeIndex === index ? 'active' : ''}`}
                                onClick={() => toggleAccordion(index)}
                            >
                                <div className="accordion-header">
                                    <i
                                        className={
                                            activeIndex === index
                                                ? "ri-focus-fill" // Icono activo
                                                : "ri-focus-line" // Icono inactivo
                                        }
                                    ></i>{" "}
                                    {option.title}
                                </div>
                                <div
                                    className="accordion-content"
                                    dangerouslySetInnerHTML={{ __html: option.content }} // Permite HTML en el contenido
                                />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Company