import React from 'react'

export function Header() {
    return (
        <header class="site-header header-main-layout-1 ast-primary-menu-enabled ast-logo-title-inline ast-menu-toggle-icon ast-mobile-header-inline" id="masthead">
            <div class="main-header-bar-wrap">
                <div class="main-header-bar">
                    <div class="ast-container">
                        <div class="ast-flex main-header-container">
                            <div class="site-branding">
                                <div class="ast-site-identity" itemtype="https://schema.org/Organization" itemscope="itemscope">
                                    <span class="site-logo-img">
                                        <a href="/" class="custom-logo-link" rel="home" aria-current="page">
                                            <img width="257" height="93" src="./assets/uploads/2020/05/LOGO-ASGL-3-257x93.png" class="custom-logo" alt="ASGL &amp; ASOC S.A. DE C.V." decoding="async" sizes="(max-width: 257px) 100vw, 257px" />
                                        </a>
                                    </span>			
                                </div>
                            </div>
                            <div class="ast-mobile-menu-buttons">
                                <div class="ast-button-wrap">
                                <button type="button" class="menu-toggle main-header-menu-toggle  ast-mobile-menu-buttons-fill " aria-controls="primary-menu" aria-expanded="false">
                                    <span class="screen-reader-text">Menú principal</span>
                                    <span class="menu-toggle-icon"></span>
                                </button>
                                </div>
                            </div>
                            <div class="ast-main-header-bar-alignment">
                                <div class="main-header-bar-navigation">
                                    <nav class="ast-flex-grow-1 navigation-accessibility" id="site-navigation" aria-label="Navegación del sitio" itemtype="https://schema.org/SiteNavigationElement" itemscope="itemscope">
                                    <div class="main-navigation">
                                        <ul id="primary-menu" class="main-header-menu ast-nav-menu ast-flex ast-justify-content-flex-end  submenu-with-border">
                                            <li>
                                                <a href="/" aria-current="page">INICIO <i class="ri-arrow-down-s-line"></i></a>
                                                <button class="ast-menu-toggle" aria-expanded="false">
                                                    <span class="screen-reader-text">Alternar menú</span>
                                                </button>
                                                <ul class="sub-menu">
                                                    <li id="menu-item-434" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-150 current_page_item menu-item-434"><a href="/" aria-current="page">¿QUIENES SOMOS?</a></li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-160" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-160">
                                                <a href="/about">SERVICIOS</a>
                                            </li>
                                            <li>
                                                <a href="/services">EMPRESAS <i class="ri-arrow-down-s-line"></i></a>
                                                <button class="ast-menu-toggle" aria-expanded="false">
                                                    <span class="screen-reader-text">Alternar menú</span>
                                                </button>
                                                <ul class="sub-menu">
                                                    <li id="menu-item-451" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-451">
                                                        <a href="/alearth">Alearth</a>
                                                    </li>
                                                    <li id="menu-item-452" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-452">
                                                        <a href="/dmc-anfitryon">DMC Anfitryon</a>
                                                    </li>
                                                    <li id="menu-item-450" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-450">
                                                        <a href="/ivma-constructora">IVMA Constructora</a>
                                                    </li>
                                                    <li id="menu-item-449" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-449">
                                                        <a href="/libera-beneficios">Libera Beneficios</a>
                                                    </li>
                                                    <li id="menu-item-603" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-603">
                                                        <a href="/libera">Libera</a>
                                                    </li>
                                                    <li id="menu-item-448" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-448">
                                                        <a href="/shintegration">Shintegration</a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li id="menu-item-156" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-156">
                                                <a href="/contact">CONTACTO</a>
                                            </li>
                                            <li style={{position:'relative'}} class="menu-item menu-item-gtranslate"></li>			
                                            <li class="ast-masthead-custom-menu-items widget-custom-menu-item">
                                                <div class="ast-header-widget-area">
                                                    <aside id="astra-widget-address-6" class="widget astra-widget-address">			
                                                        <div class="address clearfix">
                                                            <address class="widget-address widget-address-stack widget-address-icons-1">
                                                                <div class="widget-address-field">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="address-icons" width="15px" height="15px" viewbox="0 0 512 512"><path d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z"></path></svg>
                                                                    <span class="address-meta">
                                                                        <a href="tel:5551611568"><i class="ri-phone-fill"></i> (55)-51-61-15-68</a>
                                                                    </span>
                                                                </div>
                                                                <div class="widget-address-field">
                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="address-icons" width="15px" height="15px" viewbox="0 0 512 512"><path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7V400c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.6c0-5 5.7-7.8 9.7-4.7 22.4 17.4 52.1 39.5 154.1 113.6 21.1 15.4 56.7 47.8 92.2 47.6 35.7.3 72-32.8 92.3-47.6 102-74.1 131.6-96.3 154-113.7zM256 320c23.2.4 56.6-29.2 73.4-41.4 132.7-96.3 142.8-104.7 173.4-128.7 5.8-4.5 9.2-11.5 9.2-18.9v-19c0-26.5-21.5-48-48-48H48C21.5 64 0 85.5 0 112v19c0 7.4 3.4 14.3 9.2 18.9 30.6 23.9 40.7 32.4 173.4 128.7 16.8 12.2 50.2 41.8 73.4 41.4z"></path></svg>
                                                                    <span class="address-meta">
                                                                        <a href="mailto:contacto@asglasociados.com.mx"><i class="ri-mail-fill"></i> contacto@asglasociados.com.mx</a>
                                                                    </span>
                                                                </div>
                                                            </address>
                                                        </div>
                                                    </aside>
                                                </div>			
                                            </li>
                                        </ul>
                                    </div>
                                    </nav>
                                </div>
                            </div>			
                        </div>
                    </div>
                </div>
            </div>
		</header>
    )
}

export function Footer() {
    return (
        <footer class="site-footer" id="colophon" itemtype="https://schema.org/WPFooter" itemscope="itemscope" itemid="#colophon">
            <div class="ast-small-footer footer-sml-layout-2">
                <div class="ast-footer-overlay" style={{position:'relative', padding:'30px 10%'}}>
                    <div class="ast-container">
                        <div class="ast-small-footer-wrap">
                                <div class="ast-row ast-flex">
                                        <div class="ast-small-footer-section ast-small-footer-section-1 ast-small-footer-section-equally ast-col-md-6 ast-col-xs-12">
                                        Copyright © 2025 <span class="ast-footer-site-title">ASGL &amp; ASOC S.A. DE C.V.</span>						
                                        </div>
                                        <div class="ast-small-footer-section ast-small-footer-section-2 ast-small-footer-section-equally ast-col-md-6 ast-col-xs-12">
                                        <div class="ast-footer-widget-2-area">
            <aside id="astra-widget-address-5" class="widget astra-widget-address">			
                        <div class="address clearfix">
                            <address class="widget-address widget-address-stack widget-address-icons-1">
                                                        <div class="widget-address-field">
                                                            <svg xmlns="http://www.w3.org/2000/svg" class="address-icons" width="15px" height="15px" viewbox="0 0 496 512"><path d="M336.5 160C322 70.7 287.8 8 248 8s-74 62.7-88.5 152h177zM152 256c0 22.2 1.2 43.5 3.3 64h185.3c2.1-20.5 3.3-41.8 3.3-64s-1.2-43.5-3.3-64H155.3c-2.1 20.5-3.3 41.8-3.3 64zm324.7-96c-28.6-67.9-86.5-120.4-158-141.6 24.4 33.8 41.2 84.7 50 141.6h108zM177.2 18.4C105.8 39.6 47.8 92.1 19.3 160h108c8.7-56.9 25.5-107.8 49.9-141.6zM487.4 192H372.7c2.1 21 3.3 42.5 3.3 64s-1.2 43-3.3 64h114.6c5.5-20.5 8.6-41.8 8.6-64s-3.1-43.5-8.5-64zM120 256c0-21.5 1.2-43 3.3-64H8.6C3.2 212.5 0 233.8 0 256s3.2 43.5 8.6 64h114.6c-2-21-3.2-42.5-3.2-64zm39.5 96c14.5 89.3 48.7 152 88.5 152s74-62.7 88.5-152h-177zm159.3 141.6c71.4-21.2 129.4-73.7 158-141.6h-108c-8.8 56.9-25.6 107.8-50 141.6zM19.3 352c28.6 67.9 86.5 120.4 158 141.6-24.4-33.8-41.2-84.7-50-141.6h-108z"></path>
                                                            </svg>
                                                            <span class="address-meta">Avenida añil, Eje 3 Sur 571, colonia Granjas México, 6 piso, despacho 603-BIS, delegación Iztacalco, Ciudad de México, CDMX</span>
                                                        </div>
                                                    </address>
                        </div>

                        </aside>
                        <aside id="nav_menu-7" class="widget widget_nav_menu"><div class="menu-aviso-de-privacidad-container"><ul id="menu-aviso-de-privacidad" class="menu">
            <li id="menu-item-665" class="menu-item menu-item-type-post_type menu-item-object-page menu-item-665"><a href="/aviso-de-privacidad">Aviso de Privacidad</a></li>
            </ul></div></aside>
            
            </div>						</div>
                            
                                </div> 
                        </div>
                    </div>
                </div>
            </div>
		</footer>
		
    )
}

export default Header